import request from '@/http'

export const getList = (current, size, params) => {
	return request({
		url: '/api/blade-system/dict-biz/list',
		method: 'get',
		params: {
			...params,
			current,
			size,
		},
	})
}
