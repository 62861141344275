import { defineStore } from 'pinia'
import { getStore, setStore, getLocalEnums, setLocalEnums } from '@/utils/store'
import { getDictionary } from '@/api/system/dict'
import { getList } from '@/api/system/dictbiz'

const localEnums = getLocalEnums()

export const useDictStore = defineStore({
	id: 'dict',
	state: () => ({
		flowRoutes: getStore({ name: 'flowRoutes' }) || {},
		localEnums,
	}),
	actions: {
		flowRoutes() {
			return new Promise((resolve, reject) => {
				getDictionary({ code: 'flow' })
					.then(res => {
						const data = res.data.data
						this.flowRoutes = data.map(item => {
							return {
								routeKey: `${item.code}_${item.dictKey}`,
								routeValue: item.remark,
							}
						})
						setStore({ name: 'flowRoutes', content: this.flowRoutes })
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		fetchAllEnums() {
			getList()
				.then(res => {
					res.data.map(item => {
						item.children = (item.children || []).filter(child => {
							//过滤掉封存的字典
							return child.isSealed === 0
						})
					})
					setLocalEnums(res.data)
					this.localEnums = res.data
				})
				.catch(e => {
					console.log('枚举获取失败 ==>', e)
				})
		},
	},
})
