<template>
	<div class="reset-pwd">
		<h4 class="title">
			<svg-icon icon-class="back" @click="goBack"></svg-icon>
			{{ type === 'reset' ? '修改密码' : '忘记密码' }}
		</h4>
		<div class="reset-desc">请使用您注册钉钉的手机号码接收短信验证码</div>
		<div class="reset-form">
			<el-form
				class="login-form"
				:rules="formRules"
				ref="changePwdFormRef"
				:model="loginForm"
				label-width="0"
			>
				<el-form-item prop="account">
					<svg-icon icon-class="user"></svg-icon>
					<el-input
						size="small"
						@keyup.enter="handleSubmit"
						v-model="loginForm.account"
						auto-complete="off"
						maxlength="11"
						placeholder="请输入您的手机号码"
					></el-input>
				</el-form-item>
				<el-form-item prop="newPassword">
					<svg-icon icon-class="password"></svg-icon>
					<el-input
						size="small"
						@keyup.enter="handleSubmit"
						:type="isEyesOpened ? '' : 'password'"
						v-model="loginForm.newPassword"
						auto-complete="off"
						maxlength="30"
						placeholder="请输入新密码"
					></el-input>
					<svg-icon
						v-show="isEyesOpened"
						icon-class="eyes_open"
						class="eyes"
						@click="isEyesOpened = false"
					></svg-icon>
					<svg-icon
						v-show="!isEyesOpened"
						icon-class="eyes_close"
						class="eyes"
						@click="isEyesOpened = true"
					></svg-icon>
				</el-form-item>
				<el-form-item prop="newPassword1">
					<svg-icon icon-class="password"></svg-icon>
					<el-input
						size="small"
						@keyup.enter="handleSubmit"
						:type="isNewEyesOpened ? '' : 'password'"
						v-model="loginForm.newPassword1"
						auto-complete="off"
						maxlength="30"
						placeholder="请重新输入新密码"
					></el-input>
					<svg-icon
						v-show="isNewEyesOpened"
						icon-class="eyes_open"
						class="eyes"
						@click="isNewEyesOpened = false"
					></svg-icon>
					<svg-icon
						v-show="!isNewEyesOpened"
						icon-class="eyes_close"
						class="eyes"
						@click="isNewEyesOpened = true"
					></svg-icon>
				</el-form-item>
				<el-form-item prop="msgCode" class="msg-code-item">
					<svg-icon icon-class="safe"></svg-icon>
					<el-input
						size="small"
						@keyup.enter="handleSubmit"
						v-model="loginForm.msgCode"
						auto-complete="off"
						maxlength="6"
						placeholder="请输入短信验证码"
					></el-input>
					<div class="code-area">
						<div v-if="!showCountDown" class="clickable code-button" @click="requestSmsCode">
							获取验证码
						</div>
						<div v-else class="code-button code-display">重新获取{{ countDownNum }}s</div>
					</div>
				</el-form-item>
				<el-button
					type="primary"
					class="login-submit"
					:loading="isLoading"
					@click.prevent="handleSubmit"
				>
					确认修改
				</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onBeforeUnmount } from 'vue'
import { sendSmsCodeMessage, confirmResetPwd } from '@/api/user'
import { isMobile } from '@/utils/validate'
import { ElMessage } from 'element-plus'

let countDownInterval = null
const validateAccount = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('请输入手机号'))
	} else if (!isMobile(value)) {
		callback(new Error('请输入正确的手机号'))
	} else {
		callback()
	}
}

export default defineComponent({
	name: 'ResetPassword',
	props: {
		type: {
			type: String,
			default: 'reset', // 展示方式: reset 重置密码 forget 忘记密码
		},
	},
	emits: ['reset-success'],
	setup(props, context) {
		const changePwdFormRef = ref(null)
		const state = reactive({
			isLoading: false,
			showCountDown: false,
			countDownNum: 60,
			isEyesOpened: false,
			isNewEyesOpened: false,
			loginForm: {
				account: '',
				msgCode: '',
				newPassword: '',
				newPassword1: '',
			},
			formRules: {
				account: [{ validator: validateAccount, trigger: 'blur' }],
				newPassword: [
					{ required: true, message: '请输入新密码', trigger: 'blur' },
					{ min: 6, message: '密码长度最少为6位', trigger: 'blur' },
				],
				newPassword1: [
					{ required: true, message: '请输入确认密码', trigger: 'blur' },
					{ min: 6, message: '密码长度最少为6位', trigger: 'blur' },
				],
				msgCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
			},
		})
		const methods = reactive({
			clearForm() {
				state.loginForm.msgCode = ''
				state.loginForm.newPassword = ''
				state.loginForm.newPassword1 = ''
				countDownInterval && clearInterval(countDownInterval)
			},
			goBack() {
				context.emit('reset-success')
			},
			async requestSmsCode() {
				if (!state.loginForm.account) {
					ElMessage.error('请输入手机号')
					return
				}
				if (!isMobile(state.loginForm.account)) {
					ElMessage.error('请输入正确的手机号')
					return
				}
				try {
					await sendSmsCodeMessage(state.loginForm.account)
					state.showCountDown = true
					countDownInterval && clearInterval(countDownInterval)
					countDownInterval = setInterval(() => {
						if (state.countDownNum > 0) {
							state.countDownNum--
						} else {
							countDownInterval && clearInterval(countDownInterval)
							state.countDownNum = 60
							state.showCountDown = false
						}
					}, 1000)
				} catch (err) {
					ElMessage.error(err.message)
				}
			},
			async handleSubmit() {
				state.isLoading = true
				try {
					let validFlag = true
					changePwdFormRef.value.validate(valid => {
						if (!valid) {
							validFlag = false
							return false
						}
					})
					if (!validFlag) return

					if (!isMobile(state.loginForm.account)) {
						throw new Error('请填写正确的手机号码!')
					}
					if (state.loginForm.newPassword !== state.loginForm.newPassword1) {
						throw new Error('两次密码输入不一致!')
					}
					const res = (await confirmResetPwd(state.loginForm)) || {}
					if (res.code !== 200) throw new Error(res.msg)
					ElMessage.success('密码修改成功, 请重新登录!')
					context.emit('reset-success')
				} catch (err) {
					ElMessage.error(err.message)
				} finally {
					state.isLoading = false
				}
			},
		})
		onBeforeUnmount(() => {
			countDownInterval && clearInterval(countDownInterval)
		})
		return {
			changePwdFormRef,
			...toRefs(state),
			...toRefs(methods),
		}
	},
})
</script>

<style scoped lang="less">
.reset-pwd {
	.title .svg-icon {
		color: #409eff;
		cursor: pointer;
	}
	.reset-desc {
		font-size: 14px;
		color: #999;
		padding: 20px 0;
	}
	.code-area {
		position: absolute;
		right: 20px;
		font-size: 14px;
	}
	.code-button {
		color: #409eff;
	}
	.code-display {
		color: #b0b0b0;
	}
}
</style>
